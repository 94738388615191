<template>
  <div class="content-body">
    <div class="row mt-2 mg-sm-0 no-print">
      <div class="col-6">
        <div class="content-header pd-l-0">
          <div>
           <nav aria-label="breadcrumb">
              <ol class="breadcrumb">
                <li class="breadcrumb-item">
                  <a href="#">General Report</a>
                </li>
                <li
                  class="breadcrumb-item text-capitalize active"
                  aria-current="page"
                >
                   Loan Report
                </li>
                 <li
                  class="breadcrumb-item text-capitalize active"
                  aria-current="page"
                >
                  Transaction Wise
                </li>
              </ol>
            </nav>
            <h4 class="content-title content-title-xs">Loan Report</h4>
          </div>
        </div>
        <!-- content-header -->
      </div>
      <!-- col -->
      <div class="col-6 d-flex justify-content-end pr-2 pb-2">
        <div class="bg-theam-secondary d-flex m-2 p-1">
          <div class="report-tool-icon mr-3 ml-1">
            <i class="fas fa-print" title="Print" @click="print"></i>
          </div>
          <div class="report-tool-icon mr-1">
            <i class="fas fa-download" title="Download" @click="download"></i>
          </div>
        </div>
      </div>
      <!-- col -->
    </div>
    <!-- row -->
    <div class="bg-theam-secondary table-search-head pt-0">
      <div class="row">
        <div class="filter-input col-md-12 d-block mr-0 pr-0">
          <select v-model="params.pageIndex" id="capital_filter_size" @change="searchFilter()" class="mr-2 mg-t-5">
            <option value="10">10</option>
            <option value="20">20</option>
            <option value="50">50</option>
            <option value="100">100</option>
            <option value="500">500</option>
          </select>
          <select v-model="params.type" class="mr-2 mg-t-5" id="capital_filter_type" @change="searchFilter()">
            <option value="">All</option>
            <option value="0">Added</option>
            <option value="1">Settled</option>
          </select>
           <SearchDayMonthComponent
            @clicked="monthDayClicked"
          ></SearchDayMonthComponent>
          <div class="mr-2 d-inline-block">
            <label class="filter-lavel mb-0 mr-1 mg-t-5" >From</label>
            <input
              type="date"
              v-model="params.from_date"
              placeholder="From"
              class="filter-input mr-1 mg-t-5"
              id="capital_filter_date_from"
              @change="searchFilter()"
            />
            <label class="filter-lavel mb-0 mr-1 mg-t-5" >To</label>
            <input
              type="date"
              v-model="params.to_date"
              placeholder="To"
              class="filter-input mr-1 mg-t-5"
              id="capital_filter_date_to"
              @change="searchFilter()"
            />
          </div>
          <input
            type="text"
            v-model="params.searched"
            class="filter-input mg-t-5 mr-2"
            id="capital_filter_search"
            placeholder="Search..."
            @keyup="searchKeyword"
          />
          <div class="float-right mr-2 mg-t-5">
            <select
              class="filter-input ml-2 float-right"
              v-model="route"
              @change="filterReportType"
            >
              <option value="/general-report/loan">Account Wise</option>
              <option value="/general-report/loan/transaction-wise">Transaction Wise</option>
            </select>
          </div>
        </div>
      </div>
    </div>
    <div class="row py-0">
      <div class="col-md-12 overflow-x-auto">
        <table class="table table2 table-hover" id="tableData">
          <thead>
            <tr>
              <th class="wd-5p table-start-item">S.N.</th>
              <th class="wd-10p">Date</th>
              <th class="wd-15p">Loan Id</th>
              <th class="wd-15p">Type</th>
              <th class="wd-20p">Account Name</th>
              <th class="wd-10p">Payment Mode</th>
              <th class="wd-10p">Loan Amt.</th>
              <th class="wd-15p text-center table-end-item">Action</th>
            </tr>
          </thead>
          <tbody v-if="!loading && transactionWiseDatas.length > 0">
            <tr v-for="(loan, index) in transactionWiseDatas" :key="index">
              <th scope="row" class="table-start-account">{{ pageSerialNo+index }}.</th>
              <td>{{ loan.date }}</td>
              <td>#{{ loan.loan_id }} <span v-if="loan.status == 1" class="tx-danger">-Canceled</span></td>
              <td>{{ loan.loan_type == 1 ? "Settle" : "Add" }}</td>
              <td>{{ loan.account.account_name }}</td>
              <td>{{ loan.payment_method.title }} <span v-if="loan.bank_id">-{{loan.bank.bank}}</span></td>
              <td>{{ parseDigitForSlip(loan.amount )}}</td>
              <td class="text-center   table-end-item">
                <a href="javascript:;" title="View" class="mr-3" :id="'loanView'+loan.id" @click="loanSlip('loanSlip',loan.id)">
                  <i class="fas fa-eye tx-success"></i>
                </a>
              </td>
            </tr>
          </tbody>
           <tbody v-else-if="error">
            <td colspan="11" class="text-center">
              {{error_message}}
            </td>
          </tbody>
           <tbody v-else-if="!loading && transactionWiseDatas.length == 0">
            <tr>
              <td colspan="11" style="text-align: center">No records found.</td>
            </tr>
          </tbody>
          <tbody v-else-if="loading">
            <tr>
              <td colspan="11" style="text-align: center">Loading.....</td>
            </tr>
          </tbody>
         
        </table>
        <Paginate 
          v-model="page"
          :pageCount="totalPageCount"
          :containerClass="'pagination'"
          :clickHandler="clickCallback"
          v-if="totalPageCount > 1"
          />
      </div>
    </div>
    <Slip />
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import Slip from "../../Account/Loan/addSlip";
import Paginate from 'vuejs-paginate';
import SearchDayMonthComponent  from "../../../../shared/SearchDayMonthComponent";
import Services from "./Services/Services";
import _ from 'lodash';
export default {
  components: {
    Paginate,
    SearchDayMonthComponent,
    Slip,
  },
   computed: {
    ...mapGetters([
      "icons",
      "dataId",
      "dataLists",
      "removeList",
      "pagination",
      "eventMessage",
    ]),
    ...mapGetters("loanReports",["transactionWiseDatas"]),
    ...mapGetters("loans",["loanLists"])
  },
  data() {
    return {
      sessionYears: ["2021"],
      route: this.$route.path,
      params: {
        pageIndex: 10,
        searched: "",
        offset: 0,
        branch: "",
        type: "",
        from_date: "",
        to_date:"",
        day: new Date().getDate(),
        month: new Date().getMonth(),
        year: new Date().getFullYear(),
      },
      excel_params: {
        pageIndex: 10,
        searched: "",
        offset: 0,
        branch: "",
        type: "",
        from_date: "",
        to_date: new Date().toJSON().slice(0, 10).replace(/-/g, "-"),
        day: new Date().getDate(),
        month: new Date().getMonth(),
        year: new Date().getFullYear(),
      },
      totalPageCount: 0,
      pageSerialNo: 1,
      loading: true,
      error: false,
      error_message:'',
      page: 1,
    };
  },
  mounted() {
    this.getFilteredDataWithoutDates();
  },
  methods: {
    print(){
      window.print();
    },
    filterReportType() {
      this.$router.push(this.route);
    },
    clickCallback: function(page) {
      if(page == 1){

        this.pageSerialNo = 1;
      }else{
        this.pageSerialNo = ((page-1) * this.params.pageIndex) + 1 ;
      }
      this.offset = (page-1) * this.params.pageIndex;
      this.getFilteredDataWithoutDates();
    },
    monthDayClicked(value) {
      if (value) {
        this.params.day = value.day;
        this.params.month = value.month;
        this.params.year = value.year;
      }  
      this.getFilteredData();
    },
    searchFilter(){
        this.page=1;
        this.pageSerialNo=1;
        this.offset = 0;
        this.getFilteredDataWithoutDates();
    },
   searchKeyword:_.debounce(function(){
        this.page=1;
        this.pageSerialNo=1;
        this.offset = 0;
        this.getFilteredDataWithoutDates();
    },1000),
    getFilteredData() {
      this.loading = true;
      let params = {
        pageIndex: this.params.pageIndex,
        branch: this.params.branch,
        today: this.params.day,
        month: this.params.month + 1,
        type:this.params.type,
        year: this.params.year,
        from: this.params.from_date,
        to: this.params.to_date,
        searched: this.params.searched,
        offset: this.offset,
      };
       this.excel_params.pageIndex= this.params.pageIndex,
       this.excel_params.branch= this.params.branch,
        this.excel_params.today= this.params.day,
        this.excel_params.month= this.params.month + 1,
        this.excel_params.type=this.params.type,
        this.excel_params.year= this.params.year,
        this.excel_params.from= this.params.from_date,
        this.excel_params.to= this.params.to_date,
        this.excel_params.searched= this.params.searched,
        this.excel_params.offset= this.offset,
      this.ajaxRequest(params);
    },
    // without today/month section
    getFilteredDataWithoutDates() {
      this.loading = true;
      let params = {
        pageIndex: this.params.pageIndex,
        type:this.params.type,
        branch: this.params.branch,
        today: "",
        month: "",
        year: this.params.year,
        from: this.params.from_date,
        to: this.params.to_date,
        searched: this.params.searched,
        offset: this.offset,
      };
       this.excel_params.pageIndex= this.params.pageIndex,
       this.excel_params.branch= this.params.branch,
        this.excel_params.today= "",
        this.excel_params.month= "",
        this.excel_params.type=this.params.type,
        this.excel_params.year= this.params.year,
        this.excel_params.from= this.params.from_date,
        this.excel_params.to= this.params.to_date,
        this.excel_params.searched= this.params.searched,
        this.excel_params.offset= this.offset,
      this.ajaxRequest(params);
    },
    ajaxRequest(params) {
      this.loading = true;
      Services.getTransactionWiseReport(params)
        .then((res) => {
          this.$store.commit("loanReports/setTransactionWiseDatas", res.data.data.data);
          this.$store.commit("loans/setLoanLists", res.data.data.data);
          this.totalPageCount = res.data.data.total_no_of_pages;
        })
        .catch((err) => {
          this.error = true;
          if(!Object.prototype.hasOwnProperty.call(err,"response.data.error_message")) {
            if (err.response.data.error_message == "Access Denied.") {
              this.error_message = "Access Denied !!!";
              } else {
                this.error_message = "Error fetching data from the server.";
              }
          }
          this.$store.commit("loanReports/setTransactionWiseDatas", []);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    loanSlip(modal, id) {
      this.$store.commit("setDataId", id);
      this.$store.dispatch("modalWithMode", { id: modal, mode: "read" });
    },
    setNotification(msg) {
      this.$store.dispatch("enableMessage");
      this.$store.commit("setEventMessage", msg);
    },
    getData() {
      this.getFilteredDataWithoutDates();
    },
        download(){
      Services.getTransactionWiseExcel(this.excel_params).then((response)=>{
        const blob = new Blob([response.data], { type: 'application/vnd.ms-excel;charset=utf-8' });
        const elink = document.createElement("a");
            elink.download = 'loan-transaction-wise-report.xlsx';
            elink.style.display = "none";
            elink.style.font = "none";
            elink.href = URL.createObjectURL(blob);
            document.body.appendChild(elink);
            elink.click();
            URL.revokeObjectURL (elink.href);
            document.body.removeChild(elink);
      }).catch(err=>{
        console.log(err);
      });
    },
  },
};
</script>
<style scoped>
</style>
